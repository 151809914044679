import React, { useState } from 'react'
import { Box, Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyIcon from '@mui/icons-material/Key';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const drawerWidth = 300;

const theme = createTheme({
  palette: {
    primary: {
      main: '#167C80',
    },
  },
});

const styles = theme => ({
  mainHeading: {
  },
  rampayLogoClass: {
    padding: "1em",
    textAlign: "center",
    backgroundColor: "#081627",
  },
  partnerLogoClass: {
    padding: "0.2em",
  },
  logoutButtonClass: {
    marginBottom: "1em",
  },
  menuHeaders: {
    marginLeft: "20px",
  },
  footer: {
    margin: "0px",
    top: "auto",
    right: "auto",
    bottom: "20px",
    left: "0",
    position: "fixed",
    maxWidth: drawerWidth
  },
  // iconClass: {
  //   color: "#697380",
  // },
  // itemTextClass: {
  //   color: "#697380",
  // }
  iconClass: {
    color: "white",
  },
  itemTextClass: {
    color: "white",
  }
});

function DashboardLayout(props) {
  const [selectedTab, setSelectedTab] = useState(props.callingPage || "OVERVIEW");
  const handleClick = (callingOption) => {
    setSelectedTab(callingOption);
    switch (callingOption) {
      case `OVERVIEW`:
        window.location.href = `/overview`;
        break;
      case `ANALYTICS`:
        window.location.href = `/analytics`;
        break;
      case `API_KEYS`:
        window.location.href = `/apikeys`;
        break;
      case `WEBHOOKS`:
        window.location.href = `/webhooks`;
        break;
      default:
        setSelectedTab("/overview");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          color="primary"
          PaperProps={{
            sx: {
              backgroundColor: "#0F1F33",
              color: "white",
            }
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Box className={props.classes.rampayLogoClass}>
            <Typography variant="h6" noWrap component="div" className={props.classes.mainHeading}>
              Rampay
            </Typography>

          </Box>
          <Divider color="white" height="10px" />
          <Box sx={{ overflow: 'auto' }}>
            <Box style={{ height: "40px" }} />
            <Box className={props.classes.menuHeaders}>
              <Typography variant="overline" noWrap component="div">
                Partner Dashboard
              </Typography>
            </Box>
            <List>
              <ListItem key={"overview"} disablePadding disabled={selectedTab !== `OVERVIEW`}
                style={selectedTab === `OVERVIEW` ? { backgroundColor: "#083638" } : {}}
                sx={{
                  ':hover': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                <ListItemButton onClick={() => {
                  handleClick("OVERVIEW");
                }}
                >
                  <ListItemIcon>
                    <HomeIcon className={props.classes.iconClass} />
                  </ListItemIcon>
                  <ListItemText primary={"Overview"} className={props.classes.itemTextClass} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"analytics"} disablePadding disabled={selectedTab !== `ANALYTICS`}
                style={selectedTab === `ANALYTICS` ? { backgroundColor: "#083638" } : {}}
                sx={{
                  ':hover': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                <ListItemButton onClick={() => {
                  handleClick("ANALYTICS");
                }}>
                  <ListItemIcon>
                    <TrendingUpIcon className={props.classes.iconClass} />
                  </ListItemIcon>
                  <ListItemText primary={"Analytics"} className={props.classes.itemTextClass} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"apiKeys"} disablePadding disabled={selectedTab !== `API_KEYS`}
                style={selectedTab === `API_KEYS` ? { backgroundColor: "#083638" } : {}}
                sx={{
                  ':hover': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                <ListItemButton onClick={() => {
                  handleClick("API_KEYS");
                }}
                >
                  <ListItemIcon>
                    <KeyIcon className={props.classes.iconClass} />
                  </ListItemIcon>
                  <ListItemText primary={"API Keys"} className={props.classes.itemTextClass} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"documentation"} disablePadding disabled sx={{
                ':hover': {
                  bgcolor: 'primary.main',
                },
              }}>
                <ListItemButton onClick={() => {
                  window.open(
                    'https://docs.rampay.io',
                    '_blank'
                  );
                }}>
                  <ListItemIcon>
                    <ArticleIcon className={props.classes.iconClass} />
                  </ListItemIcon>
                  <ListItemText primary={"Documentation"} className={props.classes.itemTextClass} />
                  <OpenInNewIcon className={props.classes.iconClass} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"webhooks"} disablePadding disabled={selectedTab !== `WEBHOOKS`}
                style={selectedTab === `WEBHOOKS` ? { backgroundColor: "#083638" } : {}}
                sx={{
                  ':hover': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                <ListItemButton onClick={() => {
                  handleClick("WEBHOOKS");
                }}
                >
                  <ListItemIcon>
                    <SettingsIcon className={props.classes.iconClass} />
                  </ListItemIcon>
                  <ListItemText primary={"Webhooks"} className={props.classes.itemTextClass} />
                </ListItemButton>
              </ListItem>
            </List>
            <Grid container className={props.classes.footer}>
              <Grid container>
                <List>
                  <ListItem key={"Logout"} disablePadding disabled>
                    <ListItemButton onClick={() => {
                      localStorage.removeItem("loggedIn");
                      localStorage.removeItem("authToken");
                      localStorage.removeItem("partnerName");
                      localStorage.removeItem("partnerLogo");
                      window.location.reload();
                    }}>
                      <ListItemIcon>
                        <LogoutIcon className={props.classes.iconClass} />
                      </ListItemIcon>
                      <ListItemText primary={"Logout"} className={props.classes.itemTextClass} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
              <Grid container>
                <Box style={{ marginLeft: "20px" }}>
                  <Typography variant="caption" noWrap component="div" color="#697380">
                    Powered by Rampay
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default withStyles(styles)(DashboardLayout);
