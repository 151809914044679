import React, { useEffect, useState } from 'react'
import Request from '../../lib/Request'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Util from '../../lib/Util';


const handleMarkCompletedClick = (e, cellValue) => {
  if (window.confirm("Do you want to mark this order as completed?")) {
    const orderId = cellValue.row.id;
    let opts = {
      method: 'POST',
      url: '/v1/partner/sandbox/order/update',
      data: {
        orderId,
        action: `MARK_COMPLETED`,
      }
    }
    Request.Fire(opts)
      .then(data => {
        window.location.reload();
      })
      .catch(err => {
        alert("Error in updating order.");
      })
  }
}

const handleMarkFailedClick = (e, cellValue) => {
  if (window.confirm("Do you want to mark this order as failed?")) {
    const orderId = cellValue.row.id;
    let opts = {
      method: 'POST',
      url: '/v1/partner/sandbox/order/update',
      data: {
        orderId,
        action: `MARK_FAILURE`,
      }
    }
    Request.Fire(opts)
      .then(data => {
        window.location.reload();
      })
      .catch(err => {
        alert("Error in updating order.");
      })
  }
}

const columns = [
/*  {
    field: "Actions",
    renderCell: (cellValue) => {
      // if (cellValue.row.status !== `PAYMENT_PENDING`) {
      //   return (<div></div>);
      // }
      const disabled = cellValue.row.status !== `PAYMENT_PENDING`;
      return (
        <Grid>
          <IconButton
            onClick={(event) => {
              handleMarkCompletedClick(event, cellValue);
            }}
            disabled={disabled}
            color="primary"
            edge="end"
          >
            <CheckCircleOutlineIcon style={{ color: disabled ? "grey": "green" }} />
          </IconButton>
          <IconButton
            disabled={cellValue.row.status !== `PAYMENT_PENDING`}
            onClick={(event) => {
              handleMarkFailedClick(event, cellValue);
            }}
            color="primary"
            edge="end"
          >
            <CancelIcon style={{ color: disabled ? "grey": "red" }} />
          </IconButton>
        </Grid >
      );
    }
  },*/
  { field: 'id', headerName: 'ORDER ID', width: 300, type: 'number', align: "center", headerAlign: "center" },
  { field: 'status', headerName: 'STATUS', width: 200, align: "center", headerAlign: "center" },
  { field: 'fiatCurrencyCode', headerName: 'Fiat Code', width: 130, align: "center", headerAlign: "center" },
  {
    field: 'fiatCurrencyAmount',
    headerName: 'Fiat Amount',
    width: 160,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      `${Number(params.row.fiatCurrencyAmount).toFixed(2) || ''}`,
  },
  { field: 'cryptoCurrencyCode', headerName: 'Crypto Code', width: 130, align: "center", headerAlign: "center" },
  { field: 'cryptoAmount', headerName: 'Crypto Amount', width: 130, align: "center", headerAlign: "center" },
  {
    field: 'cryptoAmount',
    headerName: 'Crypto Amount',
    width: 160,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      Number(params.row.cryptoAmount) ? `${Number(params.row.cryptoAmount).toFixed(6) || ''}` : `NA`
  },
  { field: 'createdAt', headerName: 'Creation Date', width: 200, type: 'date', align: "center", headerAlign: "center" },
  // {
  //   field: 'transactionHash',
  //   headerName: 'Txn Hash',
  //   width: 230,
  //   align: "center",
  //   headerAlign: "center",
  //   renderCell: (params) => {
  //     if (params.row.transactionLink) {
  //       return (<a target="_blank" rel="noopener noreferrer" href={params.row.transactionLink}>{params.row.transactionHash}</a>);
  //     }
  //     return (<p>{params.row.transactionHash}</p>);
  //   }
  // },
];

const styles = theme => ({
  chartGrid: {
    padding: "2em",
  },
  contentGrid: {
    marginTop: "40px"
  },
  headerGrid: {
    marginTop: "40px"
  }
});

function Summary(props) {
  const [orders, setOrders] = useState([]);
  const partnerName = localStorage.getItem('partnerName');
  const partnerLogo = localStorage.getItem('partnerLogo');
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    let opts = {
      method: 'GET',
      url: '/v1/partner/orders?pageNumber=0&pageSize=1000'
    }
    Request.Fire(opts)
      .then(data => {
        setOrders(data);
      })
      .catch(err => {
        // alert(lodash.get(err, 'response.data.error', 'Something went wrong. Please try again later.'))
      })
  }, []);

  return (
    <Grid container className={props.classes.chartGrid}>
      <Grid container height={"40px"}>
        <Grid item xs={6} sm={6}>
          <Typography variant="subline2" noWrap component="div" color={"#007d82"}>
            Hi {Util.capitalizeFirstLetter(partnerName)} team, here is quick summary on your transactions.
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
        </Grid>
        <Grid item xs={2} sm={2} style={{ textAlign: "right" }}>
          <img src={partnerLogo} alt={partnerName} height="40px" />
        </Grid>
      </Grid>
      <Grid container className={props.classes.headerGrid}>
        <Grid item>
          <Typography variant="h6" noWrap component="div" color={"#007d82"}>
            Recent Orders
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={props.classes.contentGrid}>
        <Grid item xs={12} sm={12}>
          <DataGrid
            rows={orders}
            autoHeight
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
            density="compact"
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

}

export default withStyles(styles)(Summary);
